import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home/Home';
import About from './pages/About/About';
import Services from './pages/Services/Services';
import Disclosures from './pages/Disclosures/Disclosures';
import PageNotFound from './pages/404Page/PageNotFound';
import JarrettBio from './pages/About/Bios/Jarrett/JarrettBio';
import EmmettBio from './pages/About/Bios/Emmett/EmmettBio';
import Insight from './pages/Insight/Insight';
import InsightPost from './pages/Insight/InsightPost';

const AppRouter: React.FC = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<About />} />
        <Route path='/about/Jarrett_Sharp' element={<JarrettBio/>} />
        <Route path='/about/Emmett_Fehlau-Barton' element={<EmmettBio/>} />
        <Route path="/services" element={<Services />} />
        <Route path="/disclosures" element={<Disclosures />} />
        <Route path="/insight" element={<Insight />} />
        <Route path="/insight/:slug" element={<InsightPost />} />
        <Route path="*" element={<PageNotFound />}/>
      </Routes>
    </Router>
  );
};

export default AppRouter;