import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import * as contentful from 'contentful';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';
import { INLINES } from '@contentful/rich-text-types';

import Mountains from '../../images/mountains.png';


import './InsightPost.scss';

const client = contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const InsightPost = () => {
    const { slug } = useParams();
    const [blogPost, setBlogPost] = useState(null);

    const fetchBlogPost = useCallback(async () => {
        try {
            const entries = await client.getEntries({
                content_type: 'blogPost',
                'fields.slug': slug,
            });
            if (entries.items.length > 0) {
                setBlogPost(entries.items[0]);
            }
        } catch (error) {
            console.error(`Error fetching blog post: ${error}`);
        }
    }, [slug]);

    useEffect(() => {
        fetchBlogPost();
    }, [fetchBlogPost]);

    if (!blogPost) {
        return <div>Loading...</div>;
    }

    const options = {
        renderNode: {
            [INLINES.HYPERLINK]: (node) => {
                const uri = node.data.uri;
                const isExternal = /^https?:\/\//i.test(uri);
                const formattedUrl = isExternal ? uri : `http://${uri}`;

                return (
                    <a
                        href={formattedUrl}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        {node.content[0].value}
                    </a>
                );
            },
        },
    };
    
    const publishDate = new Date(blogPost.fields.publishDate);
    const formattedDate = new Intl.DateTimeFormat('en-US', {
        month: 'long',
        day: 'numeric',
        year: 'numeric',
    }).format(publishDate);

    return (
        <div className="blog-post">
            {blogPost.fields.coverImage && (
                <img
                    src={blogPost.fields.coverImage.fields.file.url}
                    alt={blogPost.fields.title}
                    className="cover-image"
                />
            )}

            <div className='title'>{blogPost.fields.title}</div>

            <div className="blog-content">
                {documentToReactComponents(blogPost.fields.content, options)}
                <p className='date'>Published: {formattedDate}</p>
            </div>

            <div className="author-info">
                <p className='author-name'>By: {blogPost.fields.author.fields.name}</p>
                {blogPost.fields.author && blogPost.fields.author.fields.authorsPicture && (
                    <img
                        src={blogPost.fields.author.fields.authorsPicture.fields.file?.url || ''}
                        alt={blogPost.fields.author.fields.name}
                        className="author-picture"
                    />
                )}
            </div>

            <div className="mountain-section">
                <img className="mountainImg" src={Mountains} alt="mountains" />
            </div>
        </div>
    );
};

export default InsightPost;