import React, { useState, useCallback, useEffect } from 'react';
import './EmmettBio.scss';
import Emmett from '../../../../images/emmett.png';
import MobileEmmett from '../../../../images/emmett_thumbnail.png';
import LinkedIn_Logo from '../../../../images/linkedin_icon.png';
import Email_Logo from '../../../../images/email_icon.png';
import Phone_Logo from '../../../../images/phone.png';
import Mountains from '../../../../images/mountains.png';

const EmmettBio: React.FC = () => {
  const [currentDescIndex, setCurrentDescIndex] = useState(0);
  const [resetTimer, setResetTimer] = useState(false);
  const [smallerView, setSmallerView] = useState(window.innerWidth <= 800);

  const descriptions = [
    <>Emmett’s introduction to the world of money was marked by a challenging and deeply personal experience. Like many families during the 2007 
    financial crisis, Emmett’s family nearly lost their home. Seeing the stress this caused left a lasting impact on him and became one of the core 
    motivations in his journey of becoming a financial planner. As he grew older and more curious, his eyes were opened to the sheer scale of our 
    financial system. His research and curiosity taught him the importance of understanding how to make these systems work in your favor. Initially 
    motivated to help his family take control of their finances and move forward with confidence, Emmett now feels it is his duty to use his knowledge 
    to assist his community in the same way.</>,
    <>Growing up in Boulder, Emmett has maintained a strong connection to his hometown. He graduated from Boulder High School in 2019 (go Panthers!) 
    and more recently earned his degree as a proud CU Buff. His college years were spent at the Leeds School of Business at CU Boulder, where he 
    obtained a Bachelor of Science in Finance with a focus on Personal Financial Planning. During this program, Emmett excelled in various core 
    financial planning topics, including Retirement Planning, Income Taxation, Investment Management, Insurance Planning, and Estate Planning. While 
    at university, Emmett worked for a leading financial planning firm in the Boulder area, further honing the knowledge and skills he acquired in 
    the classroom. In this role, Emmett learned from experienced financial advisors and support staff, bridging the gap between <i>education</i> and  
   <i>application</i>.</>,
    <>Emmett strives to empower his clients by providing them with the knowledge and confidence to navigate their financial journeys with clarity. He 
    is dedicated to offering personalized guidance that addresses each client's unique needs and goals. By leveraging his knowledge of financial 
    planning and his firsthand experience with the challenges that families face, Emmett is committed to helping his clients achieve financial 
    stability and success. His goal is to ensure that every individual he works with feels informed, supported, and confident in their financial 
    decisions.</>,
    <>Outside of work, Emmett enjoys hiking and camping in the Colorado mountains, spending time with family and friends, and exploring new interests. 
    His passion for adventure, family, and lifelong learning makes him a well-rounded and approachable advisor for his clients.</>
  ];

  const handleKeyDown = useCallback((event: KeyboardEvent) => {
    if (event.key === 'ArrowRight') {
      setCurrentDescIndex((prevIndex) => (prevIndex + 1) % descriptions.length);
      setResetTimer(!resetTimer);
    } else if (event.key === 'ArrowLeft') {
      setCurrentDescIndex((prevIndex) => (prevIndex - 1 + descriptions.length) % descriptions.length);
      setResetTimer(!resetTimer);
    }
  }, [descriptions.length, resetTimer]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => window.removeEventListener('keydown', handleKeyDown);
  }, [handleKeyDown]);

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentDescIndex((prevIndex) => (prevIndex + 1) % descriptions.length);
    }, 30000);

    return () => clearInterval(timer);
  }, [resetTimer, descriptions.length]);

  const handleTouchStart = (e: React.TouchEvent) => {
    const touchStartX = e.touches[0].clientX;

    const handleTouchEnd = (event: TouchEvent) => {
      const touchEndX = event.changedTouches[0].clientX;
      if (touchStartX - touchEndX > 50) {
        setCurrentDescIndex((prevIndex) => (prevIndex + 1) % descriptions.length);
        setResetTimer(!resetTimer);
      } else if (touchEndX - touchStartX > 50) {
        setCurrentDescIndex((prevIndex) => (prevIndex - 1 + descriptions.length) % descriptions.length);
        setResetTimer(!resetTimer);
      }
      document.removeEventListener('touchend', handleTouchEnd);
    };

    document.addEventListener('touchend', handleTouchEnd);
  };

  const handleDotClick = (index: number) => {
    setCurrentDescIndex(index);
    setResetTimer(!resetTimer);
  };

  useEffect(() => {
    const handleResize = () => {
      setSmallerView(window.innerWidth <= 800);
    };

    window.addEventListener('resize', handleResize);

    return () => window.removeEventListener('resize', handleResize);
  }, []);

  return (
    <div className="eb_about-container">
      <title>Emmett Fehlau-Barton | Sharper Image Financial Services</title>
      <div className="eb_image-container">
        <img
          className={smallerView ? 'eb_mobile-foundationImg' : 'eb_founderImg'}
          src={smallerView ? MobileEmmett : Emmett}
          alt="Financial Consultant, Emmett"
        />
      </div>
      <div className="eb_founderInfo">
        <div className="eb_founderDetails">
          <h1 className="eb_founderName">Emmett Fehlau-Barton</h1>
          <h2 className="eb_founderTitle">Financial Planner</h2>
        </div>
        {smallerView && (
          <div className="eb_social-icons">
            <a href="https://www.linkedin.com/in/emmettfehlaubarton/" target="_blank" rel="noopener noreferrer">
              <img src={LinkedIn_Logo} alt="LinkedIn" className="eb_social-icon" id="eb_linkedIn" />
            </a>
            <a href="mailto:emmett@sharperimagefinancial.com">
              <img src={Email_Logo} alt="Email" className="eb_social-icon" id="eb_email" />
            </a>
            <a href="Tel: +1(720)530-5687">
              <img src={Phone_Logo} alt="Phone Number" className="eb_social-icon" id="eb_phone" />
            </a>
          </div>
        )}
        <div className="eb_founderDescription">
          <div className="eb_description-content" onTouchStart={handleTouchStart}>
            <p>{descriptions[currentDescIndex]}</p>
            <div className="eb_indicator-container">
              {descriptions.map((_, index) => (
                <span
                  key={index}
                  className={`eb_indicator ${index === currentDescIndex ? 'active' : ''}`}
                  onClick={() => handleDotClick(index)}
                ></span>
              ))}
            </div>
          </div>
        </div>
        {!smallerView && (
          <div className="eb_social-icons">
            <a href="https://www.linkedin.com/in/emmettfehlaubarton/" target="_blank" rel="noopener noreferrer">
              <img src={LinkedIn_Logo} alt="LinkedIn" className="eb_social-icon" id="eb_linkedIn" />
            </a>
            <a href="mailto:emmett@sharperimagefinancial.com">
              <img src={Email_Logo} alt="Email" className="eb_social-icon" id="eb_email" />
            </a>
            <a href="Tel: +1(720)530-5687">
              <img src={Phone_Logo} alt="Phone Number" className="eb_social-icon" id="eb_phone" />
            </a>
          </div>
        )}
      </div>
      <img className="eb_mountainImg" src={Mountains} alt="backdrop" />
    </div>
  );
};

export default EmmettBio;