import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import * as contentful from 'contentful';
import './Insight.scss';

const client = contentful.createClient({
    space: process.env.REACT_APP_CONTENTFUL_SPACE_ID,
    accessToken: process.env.REACT_APP_CONTENTFUL_ACCESS_TOKEN,
});

const Insight = () => {
    const [blogPosts, setBlogPosts] = useState([]);
    const [searchQuery, setSearchQuery] = useState('');
    const navigate = useNavigate();

    const fetchBlogPosts = async () => {
        try {
            const entries = await client.getEntries();
            const posts = entries.items
                .filter(item => item.sys.contentType.sys.id === 'blogPost')
                .filter(item => item.fields.publish)
                .sort((a, b) => new Date(b.fields.publishDate) - new Date(a.fields.publishDate));
            setBlogPosts(posts);
        } catch (error) {
            console.error(`Error fetching blog posts: ${error}`);
        }
    };

    useEffect(() => {
        fetchBlogPosts();
    }, []);

    const formatDate = (dateString) => {
        const dateParts = dateString.split('T')[0].split('-');
        const monthNames = ["January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
        return `${monthNames[parseInt(dateParts[1]) - 1]} ${dateParts[2]}, ${dateParts[0]}`;
    };

    const handleReadMore = (slug) => {
        navigate(`/insight/${slug}`);
    };

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
    };

    const filteredPosts = blogPosts.filter((post) => {
        const publishDate = formatDate(post.fields.publishDate).toLowerCase();
        const searchTerm = searchQuery.toLowerCase();

        return (
            post.fields.title.toLowerCase().includes(searchTerm) ||
            post.fields.summary.toLowerCase().includes(searchTerm) ||
            post.fields.author?.fields.name.toLowerCase().includes(searchTerm) ||
            publishDate.includes(searchTerm)
        );
    });

    return (
        <div className="texture-background">
            <title>Insight | Sharper Image Financial Services</title>
            <div className="search">
                <input
                    className="search__input"
                    type="text"
                    placeholder="Search..."
                    value={searchQuery}
                    onChange={handleSearch}
                />
            </div>
            <div className="insight-container">
                {blogPosts.length === 0 ? (
                    <div className="no-results">
                        <p>No Posts As Of Right Now.</p>
                    </div>
                ) : filteredPosts.length === 0 ? (
                    <div className="no-results">
                        <p>No Posts Based On Search Criteria.</p>
                    </div>
                ) : (
                    filteredPosts.map((post) => (
                        <div className="blog-card" key={post.sys.id}>
                            {post.fields.coverImage && (
                                <img
                                    src={post.fields.coverImage.fields.file?.url || ''}
                                    alt={post.fields.title}
                                    className="cover-image"
                                />
                            )}
                            <h2 className="blog-title">{post.fields.title}</h2>
                            <p className="summary">{post.fields.summary}</p>
                            <button
                                className="blog-redirect-btn"
                                onClick={() => handleReadMore(post.fields.slug)}
                            >
                                READ MORE
                            </button>
                            <div className="author-info">
                                <span className="publish-date">
                                    {formatDate(post.fields.publishDate)} | {post.fields.author?.fields.name}
                                    <img
                                        src={post.fields.author?.fields.authorsPicture.fields.file?.url || ''}
                                        alt={post.fields.author?.fields.name}
                                        className="author-picture"
                                    />
                                </span>
                            </div>
                        </div>
                    ))
                )}
            </div>
        </div>
    );
};

export default Insight;